import { CoverageProgram } from './coverage-program';
import { DebitCreditReasons } from './debit-credit-reason';
import { Dispatch } from './dispatch';
import { Financier } from './financier';
import { InvoiceType } from './invoice-type';
import { Pharmacy } from './pharmacy';
import { User } from './user';

export interface PharmacyBatchAdjustment {
  id: number;
  debitCreditReasonId: number | any,
  debitCreditReason?: DebitCreditReasons,
  reason: string;
  amount: number;
  detail: string;
}

export enum PharmacyBatchStatus {
  'Generated' = 'Generated',
  'Presented' = 'Presented',
  'Audited' = 'Audited',
  'Liquidated' = 'Liquidated',
}

export interface PharmacyBatch {
  id: number;
  period: string;
  receptionDate: Date;
  pharmacyId: number;
  financierId: number;
  coverageProgramId: number;
  status: PharmacyBatchStatus;
  invoiceTypeId: number;
  invoiceNumber: string;
  presentationCode: string;
  observations: string;
  return: boolean;
  returnReason: string;
  createdAt?: Date;
  updatedAt?: Date;
  items?: Dispatch[];
  adjustments?: PharmacyBatchAdjustment[];
  pharmacy?: Pharmacy;
  financier?: Financier;
  coverageProgram?: CoverageProgram;
  invoiceType?: InvoiceType;
  auditedUser?: User;
}
