import { CoverageProgram } from './coverage-program';
import { Address } from './address';
import { Beneficiary } from './beneficiary';
import { Doctor } from './doctor';
import { DrugCompany } from './drug-company';
import { Financier } from './financier';
import { Pharmacy } from './pharmacy';
import { PrescriptionItem } from './prescription-item';

export enum PrescriptionStatus {
  Loaded = 'Loaded',
  Authorized = 'Authorized',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Audited = 'Audited',
  AutomaticallyAudited = 'AutomaticallyAudited',
  Dispensed = 'Dispensed',
  PartiallyDispensed = 'PartiallyDispensed',
}

export enum PrescriptionDistributionType {
  'DrugCompany' = 'DrugCompany',
  'Dispensation' = 'Dispensation',
}

export interface Prescription {
  id?: number;
  date?: Date;
  saleDate?: Date;
  provisionLimitDate?: Date;
  treatmentExpirationDate?: Date;
  status?: PrescriptionStatus;
  orderNumber?: string;
  orderDate?: Date;
  beneficiaryId?: number;
  financierId?: number;
  doctorId?: number;
  doctorLicense?: string;
  doctorLastName?: string;
  doctorFirstName?: string;
  doctorFullName?: string;
  distributionType?: PrescriptionDistributionType;
  shippingAddress?: Address;
  drugCompanyId?: number;
  pharmacyId?: number;
  redispense?: boolean;
  indications?: string;
  observations?: string;
  mailNotificationSent?: boolean;
  conMedicamentos?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  beneficiary?: Beneficiary;
  financier?: Financier;
  doctor?: Doctor;
  drugCompany?: DrugCompany;
  pharmacy?: Pharmacy;
  items?: PrescriptionItem[];
  purchaseOrder?: string;
  delivery: Boolean;
  coverageProgram: CoverageProgram;
  fileUrl: any;
  externalDigitalNumber?: string;
}
