import { Address } from './address';
import { CommercialAgreement } from './commercial-agreement';
import { CoverageProgram } from './coverage-program';
import { DrugCompany } from './drug-company';
import { PharmacyGroup } from './pharmacy-group';
import { Vademecum } from './vademecum';
import { Zone } from './zone';

export enum FinancierContractType {
  'Capita' = 'Capita',
  'Provision' = 'Provision',
}

export enum FinancierDistributionType {
  'DrugCompany' = 'DrugCompany',
  'Dispensation' = 'Dispensation',
}

export enum FinancierItemStatus {
  'Loaded' = 'Loaded',
  'Authorized' = 'Authorized',
  'Rejected' = 'Rejected',
  'AutomaticallyAudited' = 'AutomaticallyAudited',
  'Audited' = 'Audited',
  'Requested' = 'Requested',
  'Shipped' = 'Shipped',
  'InPharmacy' = 'InPharmacy',
  'PartiallyDispensed' = 'PartiallyDispensed',
  'Dispensed' = 'Dispensed',
  'Returned' = 'Returned',
  'Canceled' = 'Canceled',
}

export interface FinancierAgreementRule {
  id?: number;
  financierId?: number;
  coverageProgramId?: number;
  commercialAgreementId?: number;
  validityDays?: number;
  days?: number;
  codeMisValidaciones?: string;
  financierPercent?: number;
  distributionType?: FinancierDistributionType;
  automaticAudit?: boolean;
  automaticAuthorization?: boolean;
  prolongedTreatment?: boolean;
  prescriptionMaxAmount?: number;
  itemMaxAmount?: number;
  prescriptionValidityDays?: number;
  maxPrice?: number;
  periodMaxAmount?: number;
  period?: number;
  requiresApprovedBudget?: boolean;
  mandatoryValidation?: boolean;
  treatment?: boolean;
  mixedValidation?: boolean;
  coverageProgram?: CoverageProgram;
  commercialAgreement?: CommercialAgreement;
  dayAnticipation?: number;
  diagnosis?: boolean;
  informTreatmentMisValidaciones?: boolean;
}

export interface FinancierDrugCompanyRule {
  id?: number;
  financierId?: number;
  drugCompanyId?: number;
  zoneId?: number;
  vademecumId?: number;
  pharmacyGroupId?: number;
  listPercent?: number;
  drugCompany?: DrugCompany;
  zone?: Zone;
  vademecum?: Vademecum;
  pharmacyGroup?: PharmacyGroup;
}

export interface FinancierBillingRule {
  id?: number;
  financierId?: number;
  commercialAgreementId?: number;
  vademecumId?: number;
  distributionType?: FinancierDistributionType;
  costCenter?: number;
  contractType?: FinancierContractType;
  billingPriceState?: FinancierItemStatus;
  clientERPCode?: string;
  agreementCodeERP?: string;
  zoneId?: number;
}

export interface FinancierPayback {
  id?: number;
  financierId?: number;
  paybackEntityId?: number;
  vademecumId?: number;
  priceListPercent?: number;
}

export interface CompanyI {
  id: number;
  cuit?: string;
  businessName?: string;
}

export interface Financier {
  id?: number;
  cuit?: string;
  businessName?: string;
  shortName?: string;
  address?: Address;
  phone?: string;
  email?: string;
  emailReplyAddress?: string;
  logoUrl?: string;
  active?: boolean;
  codeRNOS?: string;
  codeFarmalink?: string;
  codeAdesfa?: string;
  codeERP?: string;
  codeMisValidaciones?: string;
  userMisValidaciones?: string;
  passwordMisValidaciones?: string;
  informERP?: boolean;
  informMisValidaciones?: boolean;
  automaticAudit?: boolean;
  automaticAuthorize?: boolean;
  authorizeByTreatment?: boolean;
  showCommercialBrand?: boolean;
  separatePaymentOrderGeneration?: boolean;
  companyId?: number;
  company?: CompanyI[];
  prolongedTreatment?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  agreementRules?: FinancierAgreementRule[];
  billingRules?: FinancierBillingRule[];
  drugCompanies?: FinancierDrugCompanyRule[];
  paybackEntities?: FinancierPayback[];
  observations?: string;
}
